import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from "./DurovList.module.scss";
import characterImage from "../../../assets/images/skin/durov.png";
import categoryImg1 from "../../../assets/images/category/self.png";
import categoryWebpImg1 from "../../../assets/images/category/self.webp";
import categoryImg2 from "../../../assets/images/category/business.webp";
import categoryWebpImg2 from "../../../assets/images/category/business.webp";
import categoryImg3 from "../../../assets/images/category/crypto.png";
import categoryWebpImg3 from "../../../assets/images/category/crypto.webp";
import categoryImg4 from "../../../assets/images/category/lounge.png";
import categoryWebpImg4 from "../../../assets/images/category/lounge.webp";

const BoostList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const boostPagePath = "/boost"; // Замените на ваш путь

  const handleCategoryClick = (category: string) => {
    navigate(boostPagePath, { state: { selectedCategory: category } });
  };

  return (
    <div className={styles.boostListWrapper}>
      <div className={styles.fullscreenOverlay}></div>
      <div className={styles.characterContainer}>
        <img src={characterImage} alt="Character" />
      </div>

      {/* Контейнер для категории справа */}
      <div className={styles.categoryContainerRight}   onClick={() => handleCategoryClick("Skills")}
      >
        <div className={styles.categoryItem}>
          <ImageWebp
            src={categoryImg1}
            srcSet={categoryWebpImg1}
            alt="Skills"
            className={styles.categoryIconImg}
          />
          <p className={styles.categoryNameTxt}>{t("Skills")}</p>
        </div>
      </div>

      {/* Контейнер для категории слева */}
      <div className={styles.categoryContainerLeft}   onClick={() => handleCategoryClick("Telegram")}
      >
        <div className={styles.categoryItem}>
          <ImageWebp
            src={categoryImg2}
            srcSet={categoryWebpImg2}
            alt="Telegram"
            className={styles.categoryIconImg}
          />
          <p className={styles.categoryNameTxt}>{t("Telegram")}</p>
        </div>
      </div>

      {/* Контейнер для категории снизу справа */}
      <div className={styles.categoryContainerBottomRight} onClick={() => handleCategoryClick("TON")}>
        <div className={styles.categoryItem}>
          <ImageWebp
            src={categoryImg3}
            srcSet={categoryWebpImg3}
            alt="TON"
            className={styles.categoryIconImg}
          />
          <p className={styles.categoryNameTxt}>{t("TON")}</p>
        </div>
      </div>

      {/* Контейнер для категории снизу слева */}
      <div className={styles.categoryContainerBottomLeft}  onClick={() => handleCategoryClick("Lounge")}>
        <div className={styles.categoryItem}>
          <ImageWebp
            src={categoryImg4}
            srcSet={categoryWebpImg4}
            alt="Lounge"
            className={styles.categoryIconImg}
          />
          <p className={styles.categoryNameTxt}>{t("Lounge")}</p>
        </div>
      </div>
    </div>
  );
};

export default BoostList;
