import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {AppDispatch, RootState} from "../store";

import {GetProfileRequest, GetProfileResponse} from "../../models/api/profile/getProfile";
import {IUser} from "../../models/IUser";

import {
    calculateCoinsUrl,
    claimRewardsUrl,
    fetchRequest,
    getProfileUrl,
    onboardingCompleteUrl
} from "../tools/fetchTools";
import {getLSItems, setLSItem} from "../../helpers/localStorage";

import {lsProps} from "../../utils/lsProps";
import {setFrens} from "./frensSlice";
import {setTasks} from "./tasksSlice";
import {Leagues} from "../../constants/Leagues";
import {getLeagues, setUsersCount} from "./leaguesSlice";
import {ClaimRewardsRequest} from "../../models/api/profile/claimRewards";
import { getBoosters } from "./boostSlice";


export interface ProfileState {
    user: IUser,
    getUserLoading: boolean,
    getUserError: boolean,
    energyFinished: boolean,
    isSwiping: boolean,
    swipesCount: number,
    dailyCheckinShowing: boolean,
    dailyCheckinChecked: boolean,
}

interface LsData {
    [lsProps.energy]: number,
    [lsProps.swipes]: number,
    [lsProps.energyUpdatedAt]: number,
    [lsProps.fullEnergy]: number,
    [lsProps.energyIncomePerSec]: number,
}


const initialState: ProfileState = {
    user: {
        userId: null,
        referral_id: null,
        city: null,
        firstName: "",
        lastName: "",
        languageCode: "",
        username: "",
        rank: "",
        swipeIncome: 0,
        passiveIncome: 0,
        totalScore: 0,
        energy: 0,
        pixels: 0,
        coins: 0,
        fullEnergy: 0,
        energyIncomePerSec: 0,
        coinLeague: Leagues.bronze,
        pixelLeague: Leagues.bronze,
        passiveIncomeEarned: 0,
        coinLeaguePosition: 0,
        pixelLeaguePosition: 0,
        onboardingCompleted: false,
        checkinSteak: 0,
        checkinReward: 0,
        checkinLastDate: "",
        is_subscribed: true
    },
    getUserLoading: true, // it's getting on start app
    energyFinished: false,
    getUserError: false,
    isSwiping: false,
    swipesCount: 0,
    dailyCheckinShowing: false,
    dailyCheckinChecked: false,
}


export const getUser = (data: GetProfileRequest) => async (dispatch: AppDispatch) => {
    try {
        // energy is updating from cloudStorage if exist there, otherwise it is -1
        const {energy, ...payload} = data
        const resData: GetProfileResponse = await fetchRequest<GetProfileResponse>(getProfileUrl, "POST", payload)
        const {
            user_id,
            referral_id,
            pixel_rank,
            username,
            swipe_income,
            passive_income,
            coins,
            city,
            max_energy,
            current_energy,
            pixels,
            referrals,
            tasks,
            coin_league,
            pixel_league,
            passive_income_earned,
            pixel_league_position,
            coin_league_position,
            onboarding_completed,
            check_in_streak,
            check_in_rewards,
            check_in_last_date,
            users_counter,
            is_subscribed
        } = resData
        setLSItem(lsProps.fullEnergy, max_energy)
        setLSItem(lsProps.energyIncomePerSec, 1) // it has to come from backend
        dispatch(setUser({
            userId: user_id,
            referral_id:referral_id,
            rank: pixel_rank,
            firstName: data.first_name,
            lastName: data.last_name,
            languageCode: data.language_code,
            username: username,
            swipeIncome: swipe_income,
            passiveIncome: passive_income / 4,
            totalScore: coins,
            // energy: current_energy,
            energy: energy === -1 ? current_energy : energy,
            fullEnergy: max_energy,
            city,
            coins,
            pixels,
            coinLeague: coin_league || Leagues.bronze,
            pixelLeague: pixel_league || Leagues.bronze,
            pixelLeaguePosition: pixel_league_position,
            coinLeaguePosition: coin_league_position,
            passiveIncomeEarned: passive_income_earned,
            onboardingCompleted: onboarding_completed,
            energyIncomePerSec: 1,
            is_subscribed,
            checkinSteak: check_in_streak,
            checkinReward: check_in_rewards,
            checkinLastDate: check_in_last_date,
        } as IUser));

        if (referrals) {
            dispatch(setFrens({
                frens: referrals.users,
                frensCount: referrals.referral_count,
                coins: referrals.coins
            }))
        }
        if (tasks) dispatch(setTasks(tasks))
        if (users_counter) dispatch(setUsersCount(users_counter))
    } catch (error) {
        dispatch(getUserFail())
    }

}


export const calculateUserCoins = createAsyncThunk<number | undefined, {
    user_id: number,
    swipes: number,
    energy: number
} | undefined, { rejectValue: string }>(
    'profile/calculateUserCoins',
    async (payload, {rejectWithValue, getState}) => {
        try {
            // on application mounting it will set from CloudStorage, otherwise from redux store
            const user_id = payload?.user_id || (getState() as RootState).profile.user.userId
            const swipes = payload?.swipes || (getState() as RootState).profile.swipesCount
            const energy = payload?.energy || (getState() as RootState).profile.user.energy

            if (!user_id) return;
            if (swipes) {
                await fetchRequest<GetProfileResponse>(calculateCoinsUrl, "POST", {user_id, swipes, energy})
            }
            return swipes;
        } catch (error) {
            return rejectWithValue("Error")
        }
    }
)

export const claimRewards = createAsyncThunk<void, void, { rejectValue: string }>(
    'profile/claimRewards',
    async (_, {rejectWithValue, getState}) => {
        try {
            const state = (getState() as RootState)
            const user_id = state.profile.user.userId
            const username = state.profile.user.username
            const firstName = state.profile.user.firstName
            const lastName = state.profile.user.lastName
            const languageCode = state.profile.user.languageCode

            if (!user_id) return;

            const reqData: ClaimRewardsRequest = {
                id: user_id,
                username,
                first_name: firstName,
                language_code: languageCode,
                last_name: lastName,
            }
            await fetchRequest<void>(claimRewardsUrl, "POST", reqData)

        } catch (error) {
            return rejectWithValue("Error")
        }
    }
)

export const completeOnboarding = createAsyncThunk<void, void, { rejectValue: string }>(
    'profile/completeOnboarding',
    async (_, {rejectWithValue, getState}) => {
        try {
            const state = (getState() as RootState)
            const user_id = state.profile.user.userId
            const username = state.profile.user.username
            const firstName = state.profile.user.firstName
            const lastName = state.profile.user.lastName
            const languageCode = state.profile.user.languageCode

            if (!user_id) return;

            const reqData: ClaimRewardsRequest = {
                id: user_id,
                username,
                first_name: firstName,
                language_code: languageCode,
                last_name: lastName,
            }
            await fetchRequest<void>(onboardingCompleteUrl, "POST", reqData)

        } catch (error) {
            return rejectWithValue("Error")
        }
    }
)


export const calculateAndInitUserData = (payload: Omit<GetProfileRequest, "energy">) => async (dispatch: AppDispatch) => {
    try {
        getLSItems([
            lsProps.energy,
            lsProps.swipes,
            lsProps.energyUpdatedAt,
            lsProps.fullEnergy,
            lsProps.energyIncomePerSec,
        ], async (_, result) => {
            if (result) {
                const lsData = result as any as LsData
                console.log({lsData})
                const isLsInited = !!(lsData.energy)

                const newEnergy = (Date.now() - +lsData.energyUpdatedAt) / 1000 * +lsData.energyIncomePerSec
                let energy = isLsInited
                    ? +lsData.energy + Math.floor(newEnergy)
                    : -1
                if (energy >= +lsData.fullEnergy) energy = +lsData.fullEnergy

                if (isLsInited && +lsData.swipes) {
                    await dispatch(calculateUserCoins({
                        user_id: payload.id,
                        energy,
                        swipes: +lsData.swipes,
                    }))
                }
                dispatch(getLeagues())
                dispatch(getBoosters(payload));
                dispatch(getUser({...payload, energy}))
            }
        })
    } catch (error) {
        console.error("Error performing multiple actions", error);
    }
};

export const saveUpdatesInLocStore = () => (_: AppDispatch, getState: () => RootState) => {
    const energy = getState().profile.user.energy
    const swipesCount = getState().profile.swipesCount
    setLSItem(lsProps.energy, energy)
    setLSItem(lsProps.swipes, swipesCount)
    setLSItem(lsProps.energyUpdatedAt, Date.now())
}

const setEnergyHelper = (state: ProfileState, energy: number) => {
    let payload = energy <= 0 ? 0 : energy
    state.energyFinished = !payload;
    state.user.energy = payload;
};


export const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {
        setEnergy(state, {payload}: { payload: number }) {
            setEnergyHelper(state, payload)
        },
        swipe(state) {
            setEnergyHelper(state, state.user.energy - 1)
            if(state.user.energy) {
                state.user.totalScore = state.user.totalScore + state.user.swipeIncome
                state.swipesCount += 1
            }
        },
        toggleIsSwiping(state, {payload}) {
            state.isSwiping = payload
        },
        updateUserCoins(state, {payload}) {
            state.user.totalScore += payload
        },

        updateUserCoinsPerHour(state, { payload }) {
            state.user.passiveIncome += payload;
          },
      
          setUser(state, { payload }) {
            state.user = payload;
            state.getUserLoading = false;
          },

        getUserFail(state) {
            state.getUserLoading = false
            state.getUserError = true
        },
        showDailyCheckin(state) {
            state.dailyCheckinShowing = true
        },
        hideDailyCheckin(state) {
            state.dailyCheckinShowing = false
        },
        checkDailyCheckin(state) {
            state.dailyCheckinChecked = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(calculateUserCoins.fulfilled, (state, {payload}) => {
                if (payload) {
                    state.swipesCount = state.swipesCount - payload < 0 ? 0 : state.swipesCount - payload
                    setLSItem(lsProps.swipes, state.swipesCount)
                }
            })

            .addCase(claimRewards.pending, (state) => {
                state.user.passiveIncomeEarned = 0
            })
            .addCase(completeOnboarding.pending, (state) => {
                state.user.onboardingCompleted = true

            })
    }
})

export const {
    setEnergy,
    swipe,
    toggleIsSwiping,
    setUser,
    getUserFail,
    updateUserCoins,
    showDailyCheckin,
    hideDailyCheckin,
    checkDailyCheckin,
    updateUserCoinsPerHour,
} = profileSlice.actions;


export default profileSlice.reducer;
