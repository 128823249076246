import { coinImg, coinWebpImg } from "../../../assets/images";
import { useAppSelector } from "../../../hooks/redux";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";

import styles from "./DurovHeader.module.scss"


const BoostHeader = () => {
const coins = useAppSelector(state => state.profile.user.totalScore)
const passiveIncome = useAppSelector(
  (state) => state.profile.user.passiveIncome
);

  return (
    <div className={styles.boostHeader}>
      <div className={styles.boostHeader__coins}>
        <div className={styles.boostHeader__coinItem}>
          <ImageWebp
            src={coinImg}
            srcSet={coinWebpImg}
            alt="coin"
            className={styles.boostHeader__coinImg}
          />
          <span className={styles.boostHeader__coinTxt}>{coins}</span>
        </div>
        <div className={styles.boostHeader__coinItem}>
          <ImageWebp
            src={coinImg}
            srcSet={coinWebpImg}
            alt="coin"
            className={styles.boostHeader__coinImg}
          />
          <span className={styles.boostHeader__coinTxt}>+{passiveIncome} /hour</span>
        </div>
      </div>
      {/* <h3 className={`${styles.boostHeader__title} brightText`}>Build your crypto-empire</h3> */}
    </div>
  );
};

export default BoostHeader;
